import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@biblioteksentralen/react";
import { ReactNode, useEffect, useState } from "react";

type ModalContainerProps = {
  heading?: string | ReactNode;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  footer?: ReactNode;
  modalFooterProps?: ModalFooterProps;
} & ModalProps;

export const ModalContainer = ({
  heading,
  footer,
  children,
  modalContentProps,
  modalBodyProps,
  modalFooterProps,
  ...modalProps
}: ModalContainerProps) => {
  const viewPortHeight = useViewportHeight();

  return (
    <Modal isCentered scrollBehavior="inside" {...modalProps}>
      <ModalOverlay />
      <ModalContent
        /**
         * base-stylingen her prøver å sørge for at modalen skal funke på mobil, spesielt mtp når tastaturet er åpent, feks i innloggingsmodal
         * Prøvde å fikse dette med ren css, bla med nye css-props som "dvh", men fikk det ikke til å funke i chrome (https://developer.chrome.com/blog/viewport-resize-behavior, her snakkes det også om "interactive-widget", men virket som en litt skjør løsning som ikke nødvendigvis funker i alle nettlesere, så forfulgte ikke det sporet)
         * Eneste løsningen jeg fant som løste dette for chrome på mobil var en kombinasjon av position: fixed og visualViewport.height
         * - https://www.smashingmagazine.com/2023/12/new-css-viewport-units-not-solve-classic-scrollbar-problem/
         * */
        inset={{ base: "0.5rem .5rem auto .5rem", md: undefined }}
        position={{ base: "fixed", md: "relative" }}
        width={{ base: "unset", md: "auto" }}
        maxWidth={{ md: "calc(100vh - 2rem)" }}
        maxHeight={{
          base: viewPortHeight ? `calc(${viewPortHeight}px - 1rem)` : "calc(100vh - 1rem)",
          md: `calc(100vh - 2rem)`,
        }}
        margin={{ md: "1rem" }}
        minWidth={{ md: "22rem" }}
        {...modalContentProps}
      >
        <ModalHeader>{heading} </ModalHeader>
        <ModalCloseButton />
        <ModalBody {...modalBodyProps}>{children}</ModalBody>
        {footer && (
          <ModalFooter justifyContent="center" {...modalFooterProps}>
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState<number | undefined>();

  const updateViewport = () => {
    if (typeof window === "undefined" || typeof window.visualViewport === "undefined") return;
    setViewportHeight(window.visualViewport?.height);
  };

  useEffect(() => {
    updateViewport();
    window.visualViewport?.addEventListener("resize", updateViewport);
    return () => window.visualViewport?.removeEventListener("resize", updateViewport);
  }, []);

  return viewportHeight;
};
