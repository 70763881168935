/* eslint-disable i18next/no-literal-string */
import {
  Box,
  BoxProps,
  Button,
  HStack,
  Heading,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from "@biblioteksentralen/react";
import { PropsWithChildren, useState } from "react";
import { HelpCircle, X } from "react-feather";
import { useIsBibsentEmployee } from "../components/sanityAuth/useIsBibsentEmployee";
import { useIsGlobalAdmin } from "../components/sanityAuth/useIsGlobalAdmin";
import { useSanityAuth } from "../components/sanityAuth/useSanityAuth";

export const ShowForGlobalAdmins = ({ children, ...chakraProps }: PropsWithChildren & BoxProps) => {
  const isBibsentEmployee = useIsBibsentEmployee();
  const isGlobalAdmin = useIsGlobalAdmin();
  const show = isBibsentEmployee || isGlobalAdmin;
  const [isOpen, setIsOpen] = useState(true);

  if (!show || !isOpen) return null;

  return (
    <Box background="yellow.200" {...chakraProps}>
      <HStack alignItems="center" spacing="0" marginBottom="-.3rem">
        <Box fontSize=".6rem" fontWeight={600} padding=".1rem .2rem">
          Global admin info
        </Box>
        <GlobalAdminHelp />
        <Button size="sm" marginLeft="auto" onClick={() => setIsOpen(false)} variant="tertiary">
          <X aria-label="Lukk" />
        </Button>
      </HStack>
      {children}
    </Box>
  );
};

export const ShowJSONForGlobalAdmin = (props: { json?: object; title?: string }) => (
  <ShowForGlobalAdmins padding=".5rem" borderRadius="md">
    <Stack>
      {props.title && (
        <Heading marginTop="1rem" size="sm">
          {props.title}
        </Heading>
      )}
      <Box as="pre" fontSize=".65rem">
        {JSON.stringify(props.json, null, 2)}
      </Box>
    </Stack>
  </ShowForGlobalAdmins>
);

const GlobalAdminHelp = () => {
  const auth = useSanityAuth();

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          size="xs"
          variant="ghost"
          _hover={{ backgroundColor: "blackAlpha.200" }}
          icon={<HelpCircle size="1em" />}
          aria-label="Hva er global admin info?"
        />
      </PopoverTrigger>
      <PopoverContent fontSize="sm" maxWidth="100vw" /* Avoid extending outside smaller screens */>
        <PopoverArrow />
        <PopoverHeader fontWeight={600}>Global admin info</PopoverHeader>
        <PopoverBody>
          <Stack>
            <Text>
              Dette panelet vises fordi du er logget inn i Sanity med Biblioteksentralen-epost eller har
              admin-rettigheter.
            </Text>
            <Text>
              Du er logget inn med <b>{auth.email}</b> og har disse rollene:{" "}
              <b>{auth.roles?.map((role) => role.name).join(", ")}</b>
            </Text>
            <Link alignSelf="flex-end" as="button" onClick={auth.logOut}>
              Logg ut for å skjule alle slike paneler
            </Link>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
