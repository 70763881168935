import { Button, HStack, Link, LinkProps, Stack, Text, useDisclosure } from "@biblioteksentralen/react";
import { ModalContainer } from "../components/ModalContainer";
import { useTranslation } from "../utils/hooks/useTranslation";

type Props = {
  heading: string;
  bodyText: string;
  buttonText?: string;
  href?: string;
} & LinkProps;

export const GoToOldMinSideModal = ({ heading, bodyText, buttonText, href, ...chakraProps }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Link as="button" type="button" onClick={onOpen} {...chakraProps}>
        {heading}
      </Link>
      <ModalContainer isOpen={isOpen} onClose={onClose} isCentered heading={heading}>
        <Stack spacing="2rem">
          <Text>{bodyText}</Text>
          <HStack alignSelf="flex-end" spacing="1rem">
            <Button variant="secondary" onClick={onClose}>
              {t("Lukk")}
            </Button>
            {buttonText && (
              <Button as="a" href={href} target="_blank" alignSelf="flex-end">
                {buttonText}
              </Button>
            )}
          </HStack>
        </Stack>
      </ModalContainer>
    </>
  );
};
